<template>
  <div class="box">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="search()">
      <table class="detailTable_search" style="width: 100%">
        <tr>
          <td class="titleTd width80px">日期</td>
          <td class="valueTd">
            <div class="block">
              <el-date-picker
                v-model="dataForm.time"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="changeHandler"
                :picker-options="pickerOptions">
              </el-date-picker>
            </div>
          </td>
          <td class="titleTd width80px">区域</td>
              <td class="valueTd">
                <region-tree-jurisdiction :rootId="gridId !== '' ? gridId : regionId" placeholder="请选择区域" v-model="dataForm.regionId" @typeChange="typeChange"> </region-tree-jurisdiction>
<!--                <region-tree :rootId="gridId !== '' ? gridId : regionId" placeholder="请选择区域" v-model="dataForm.regionId" @typeChange="typeChange"> </region-tree>-->
          </td>
        </tr>
      </table>
    </el-form>
    <div class="wxts_msg_search" >
        <span style="margin-left: 15px">截止统计时间:{{jzrq}}</span>
      <div class="f_t">
        <el-button-group  >
          <el-button size="mini" @click="search(dataForm.type)" type="success" :disabled="dataListLoading">查询</el-button>
            <el-button size="mini" type="warning" @click="exportData()">{{ $t('export') }}</el-button>
            <el-button size="mini" type="primary" @click="goBack()" icon="el-icon-back" v-if="orgIdList.length>0" :disabled="dataListLoading">返回</el-button>
        </el-button-group>
      </div>
    </div>
    <div class="">

      <div v-loading="dataListLoading" id="coverTwoId" class="coverClass"></div>
        <el-table
                :data="dataList"
                style="width: 100%"
                :summary-method="getSummaries"
                show-summary>
            <el-table-column
                    label="区域"
                    align="center"
                    style="color:#f5f7fa">
                <template slot-scope="scope">
                    <a style="color: blue;cursor: pointer" @click="area(scope.row.areaCode)"
                       v-if="scope.row.type!=4">{{scope.row.areaname}}</a>
                    <span v-else>{{scope.row.areaname}}</span>
                </template>
            </el-table-column >
                <el-table-column
                        prop="singleNetwork"
                        label="单向网"
                        align="center"
                >
                    <template slot-scope="scope" >
                        <span>{{scope.row.singleNetwork}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="doubleNetwork"
                        label="双向网"
                        align="center"
                >
                    <template slot-scope="scope" >
                        <span >{{scope.row.doubleNetwork}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="agricultureNetwork"
                        label="农网"
                        align="center"
                >
                    <template slot-scope="scope" >
                        <span>{{scope.row.agricultureNetwork}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                prop="count"
                label="合计"
                align="center"
                >
                </el-table-column>
        </el-table>
    </div>

  </div>
</template>

<script>
import {twoCoverCount,querySubRegion} from "@/api/kanban/secondLevel";
export default {
  data(){
    return{
      dataList: [],
      bjorgid:'',
      jzrq:'',
      orgIdList: [],
      dataListLoading: false,
      countconvert: true,
      dataForm: {
        time:[],
        regionId:'',
        startTime:'',
        endTime:''
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近一年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },

  components: {

  },

  activated() {

  },

  created(){
    this.getNowDate()
  },

  mounted() {
    this.poTwoCoverCount()
  },

  updated(){

  },

  computed: {
    regionId: {
      get() {
        const newregionId = this.$store.state.user.regionId;
        if(newregionId !== undefined){
          return newregionId;
        }else{
          return ''
        }
      }
    },
    gridId: {
      get() {
        const newgridId = this.$store.state.user.gridId;
        if (newgridId !== undefined) {
            return newgridId;
        } else {
          return ''
        }
      }
    }
  },

  methods: {
    changeHandler(val){
      this.dataForm.time = val;
      if(this.dataForm.time != null){
        this.dataForm.startTime = this.dealdate(this.dataForm.time[0]);
        this.dataForm.endTime = this.dealdate(this.dataForm.time[1]);
      }else{
        this.dataForm.startTime = '';
        this.dataForm.endTime = '';
      }
    },

    poTwoCoverCount(areaCode) {
      this.countconvert = true
      const _this = this;
      if(areaCode===undefined){
        twoCoverCount(
                {
                  startTime: _this.dataForm.startTime,
                  endTime: _this.dataForm.endTime,
                  areaCode:_this.dataForm.regionId ? _this.dataForm.regionId : (_this.gridId !== '' ? _this.gridId : _this.regionId)
                }
        ).then(({data}) => {
          if (data && data.data && data.code === 0) {
            _this.dataList = data.data.list;
            _this.bjorgid = data.data.bjorgid;
            const newList = []
            _this.dataList.forEach(element => {
              newList.push(element.areaname)
            });
            _this.coverChart(newList,_this.dataList)
          }
        })
      }else{
        twoCoverCount(
                {
                  startTime: _this.dataForm.startTime,
                  endTime: _this.dataForm.endTime,
                  areaCode:areaCode
                }
        ).then(({data}) => {
          if (data && data.data && data.code === 0) {
            _this.dataList = data.data.list;
            _this.bjorgid = data.data.bjorgid;
            const newList = []
            _this.dataList.forEach(element => {
              newList.push(element.areaname)
            });
            _this.coverChart(newList,_this.dataList)
          }
        })
      }

    },

    coverChart(newList,datas){
      var myChart = this.$echarts.init(document.getElementById('coverTwoId'));
      var option;
      option = {
        //滚动条
          dataZoom: {
            show: true,
            // realtime: true,
            height: 5,
            bottom: 10,
            start: 0,
            end: 50,
            showDetail:false
          },
        legend: {
          data: ['单向网', '双向网', '农网'],
          orient: 'horizontal',
          y: 'top',
          x: 'center',
          // itemGap: 40
        },
        tooltip: {
          trigger: 'axis',
          axisPointer : {            // 坐标轴指示器，坐标轴触发有效
            type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: (params) => {
            return params[0].name + '<br>' +params[0].marker + ' ' + params[0].seriesName + ': ' + params[0].data + ' 万' + '<br>'+
              params[1].marker + ' ' + params[1].seriesName + ': ' + params[1].data + ' 万' + '<br>'+
              params[2].marker + ' ' + params[2].seriesName + ': ' + params[2].data + ' 万'+ '<br>'
          },
        },
        grid: {
          // left: '25',
          //right: '15%',
          bottom: '10',
          top: "100",
          containLabel: true
        },
        xAxis : {
          type : 'category',
          axisLabel: {
            //x轴文字的配置
            show: true,
            interval: 0,//使x轴文字显示全
            rotate: 40,
            lineHeight: 18,
            formatter: function(params) {
              var newParamsName = ''// 最终拼接成的字符串
              var paramsNameNumber = params.length// 实际标签的个数
              var provideNumber = 10// 每行能显示的字的个数
              // 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
              if (paramsNameNumber > provideNumber) {
                // ********重点在这里********
                newParamsName = params.substring(0, 10) + '..'// 最终拼成的字符串
              } else { // 将旧标签的值赋给新标签
                newParamsName = params
              }
              // 将最终的字符串返回
              return newParamsName
            }
          },
          data : newList
        } ,
        yAxis: {
          type: 'value'
        },
        series : [ {
          name : '单向网',
          type : 'bar',
          barWidth: 20,//柱图宽度
          // label: {
          //   normal: {
          //     show: true,
          //     position: 'top',
          //     textStyle: {
          //       color: 'black'
          //     }
          //   }
          // },
          data : (function() {
            var data = [];
            datas.forEach(function(item) {
              data.push(item.singleNetwork);
            })
            return data;
          })()
        }, {
          name : '双向网',
          type : 'bar',
          barWidth: 20,//柱图宽度
          // label: {
          //   normal: {
          //     show: true,
          //     position: 'top',
          //     textStyle: {
          //       color: 'black'
          //     }
          //   }
          // },
          data : (function() {
            var data = [];
            datas.forEach(function(item) {
              data.push(item.doubleNetwork);
            });
            return data;
          })()
        }, {
          name : '农网',
          type : 'bar',
          barWidth: 20,//柱图宽度
          // label: {
          //   normal: {
          //     show: true,
          //     position: 'top',
          //     textStyle: {
          //       color: 'black'
          //     }
          //   }
          // },
          data : (function() {
            var data = [];
            datas.forEach(function(item) {
              data.push(item.agricultureNetwork);
            });
            return data;
          })()
        }]

      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      })
      this.dataListLoading = false;
    },
    // 查询
    search(type){
      if(type==='4'){
        this.$message({
          message: '该区域无下属网格，不能查询！',
          type: 'warning'
        })
      }else{
        this.poTwoCoverCount()
      }

    },
    typeChange(data){
      this.dataForm.type = data.type
    },

    getObjectValues(object){
      var values = [];
      for (var property in object)
      values.push(object[property]);
      return values;
    },
    // 日期转换
    dealdate(date) {
      const dt = new Date(date)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      return `${y}-${m}-${d}`
    },
    // 地区钻取
    area (areacode) {
      if(this.dataForm.regionId == ''){
        this.orgIdList.push(this.regionId)
      }else{
        this.orgIdList.push(this.dataForm.regionId)
      }
      this.dataForm.regionId=areacode
      this.poTwoCoverCount(areacode)
    },
    goBack () { // 回退
        this.dataForm.regionId = this.orgIdList[this.orgIdList.length - 1]
        this.poTwoCoverCount(this.dataForm.regionId);
        this.orgIdList.splice(this.orgIdList.length - 1, 1)
      },
    exportData(){
      const token = this.$cookie.get('x-tr-mimosa-token') // this.$cookie.get('token')
      // 导出数据
      const time = new Date().getTime()
      const url = this.$http.adornUrl(`/dataflow/olfcoveruser/export?` +
        'token=' + token +
        '&time=' + time +
        '&startTime='+ this.dataForm.startTime+
        '&endTime='+ this.dataForm.endTime+
      '&areaCode='+ (this.dataForm.regionId ? this.dataForm.regionId : (this.gridId !== '' ? this.gridId : this.regionId))
      )
      window.location.href = url
    },
    /**
     * 查询当天日期
     */
    getNowDate() {
      const timeOne = new Date()
      const year = timeOne.getFullYear()
      let month = timeOne.getMonth() + 1
      let day = timeOne.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      const NOW_MONTHS_AGO = `${year}-${month}-${day}`
      this.jzrq = NOW_MONTHS_AGO
      return NOW_MONTHS_AGO
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ''
        } else {
          sums[index] = 'N/A'
        }
      })

      return sums
    },

  }
}
</script>

<style scoped>
  .box{
    width: 100%;
    height:100%;
  }
  .coverClass{
    margin-top: 20px;
    width: 100%;
    height: 350px;

  }
  .stateClass{
    width:100%;
    height:90%;
    display: flex;
    align-items: center;
  }
  .b-sousuo{
    display: flex;
  }
  /* .block{
    margin-right: 10px;
  } */
</style>
